










































































import { Component, Mixins } from 'vue-property-decorator';
import { PaginateAudits_paginateAudits_data } from '@/types/intrador';
// @ts-ignore
import { DropdownMenuItem } from '@/components/elements/dropdown/DropdownButton.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';
import PlanningCard from '@/components/planning/Card.vue';
import CloseAuditAction from '@/components/audits/actions/CloseAuditAction.vue';
import DeleteAuditAction from '@/components/audits/actions/DeleteAuditAction.vue';
import LastAudit from '@/components/planning/partials/LastAudit.vue';
import Stats from '@/components/planning/partials/Stats.vue';
import Score from '@/components/planning/partials/Score.vue';
import Error from '@/components/elements/error/Error.vue';
import Anomalies from '@/components/planning/partials/Anomalies.vue';
import SwimlaneMixin from '@/components/planning/SwimlaneMixin';
import Chart from '@/components/planning/partials/Chart.vue';
import ExtendAuditAction from '@/components/planning/partials/ExtendAuditAction.vue';
import Infinite from '@/components/planning/partials/Infinite.vue';
import TrustedLabel from '@/components/TrustedLabel.vue';
import OnHoldLabel from '@/components/audits/OnHoldLabel.vue';
import {getInspectionStatusTranslation} from '@/plugins/inspection/InspectionStatus';

@Component({
  components: {
    OnHoldLabel,
    Infinite,
    ExtendAuditAction,
    Chart,
    Anomalies,
    CloseAuditAction,
    DeleteAuditAction,
    Error,
    LastAudit,
    PlanningCard,
    Score,
    Spinner,
    Stats,
    TrustedLabel,
  },
})
export default class ToReview extends Mixins(SwimlaneMixin) {
  public key = 'paginateAudits';

  private selected: PaginateAudits_paginateAudits_data[] = [];

  private inspectionStatusTranslations = getInspectionStatusTranslation;

  private get context(): DropdownMenuItem[] {
    return [{
      name: this.$it('planning.view-audit.title', 'View Audit') as string,
      icon: 'fa fa-search',
      action: (audit: PaginateAudits_paginateAudits_data) => {
        this.openAudit(audit);
      },
    }, {
      name: this.$it('planning.close-audit.title', 'Close Audit') as string,
      icon: 'fa fa-check',
      permission: 'audits-close',
      action: (audit: PaginateAudits_paginateAudits_data) => {
        this.selected = [audit];
        this.$nextTick(() => {
          (this.$refs.closeAuditAction as CloseAuditAction).open();
        });
      },
    }, {
      name: this.$it('planning.extend-audit.title', 'Extend Audit') as string,
      icon: 'far fa-calendar-plus',
      permission: 'audits-edit', // TODO: permission to extend audit
      action: (audit: PaginateAudits_paginateAudits_data) => {
        this.selected = [audit];
        this.$nextTick(() => {
          (this.$refs.extendAuditAction as ExtendAuditAction).open();
        });
      },
    }, {
      name: this.$it('planning.delete-audit.title', 'Delete Audit') as string,
      icon: 'fa fa-times',
      permission: 'audits-delete',
      action: (audit: PaginateAudits_paginateAudits_data) => {
        this.selected = [audit];
        this.$nextTick(() => {
          (this.$refs.deleteAuditAction as DeleteAuditAction).open();
        });
      },
    }, {
      name: this.$it('planning.view-branch.title', 'View Branch') as string,
      icon: 'fa fa-warehouse',
      action: (audit: PaginateAudits_paginateAudits_data) => {
        this.$router.push({ name: 'branches-detail', params: { id: audit.dealer.id, branchId: audit.branch.id } });
      },
    }];
  }

  private openAudit(audit: PaginateAudits_paginateAudits_data) {
    this.$router.push({
      name: 'audits-detail',
      params: {
        auditId: audit.id,
      },
    });
  }
}
