









import { Component, Prop, Vue } from 'vue-property-decorator';
import { Branch_branch, UpdateBranchVariables } from '@/types/intrador';
import ConfirmActionModal from '@/layouts/back-office/elements/actions/ConfirmActionModal.vue';

@Component({
  components: { ConfirmActionModal },
})
export default class DeactivateAudit extends Vue {
  @Prop(Array) private refetchQueries!: any[];
  @Prop(Object) private branch!: Branch_branch | null;

  private get variables(): UpdateBranchVariables | null {
    if (!this.branch) {
      return null;
    }

    return {
      id: this.branch.id,
      address: {
        nextAudit: null,
        auditFrequency: null,
      },
    };
  }

  private get modal() {
    return this.$refs.modal as ConfirmActionModal;
  }

  public open() {
    this.$nextTick(() => {
      this.modal.open();
    });
  }

  public close() {
    this.$nextTick(() => {
      this.modal.close();
    });
  }
}
