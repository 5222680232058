












import { Component, Prop, Vue } from 'vue-property-decorator';
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';

@Component({
  components: {
    InfiniteLoading,
    Spinner,
  },
})
export default class Infinite extends Vue {
  @Prop(String) protected identifier!: string;
  @Prop(Function) protected infinite!: ((stateChanger: StateChanger) => void);
}
