






import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class AutomaticPlannedLabel extends Vue {
  @Prop({type: [Object, String]}) protected date!: Date|string;
}
