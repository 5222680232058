
































































































import { Component, Mixins } from 'vue-property-decorator';
import { PaginateAudits_paginateAudits_data } from '@/types/intrador';
// @ts-ignore
import { DropdownMenuItem } from '@/components/elements/dropdown/DropdownButton.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';
import PlanningCard from '@/components/planning/Card.vue';
import DeleteAuditAction from '@/components/audits/actions/DeleteAuditAction.vue';
import LastAudit from '@/components/planning/partials/LastAudit.vue';
import Stats from '@/components/planning/partials/Stats.vue';
import Error from '@/components/elements/error/Error.vue';
import ExtendAuditAction from '@/components/planning/partials/ExtendAuditAction.vue';
import SwimlaneMixin from '@/components/planning/SwimlaneMixin';
import Chart from '@/components/planning/partials/Chart.vue';
import Score from '@/components/planning/partials/Score.vue';
import TransferInspections from '@/components/inspection/actions/TransferInspections.vue';
import Infinite from '@/components/planning/partials/Infinite.vue';
import TrustedLabel from '@/components/TrustedLabel.vue';
import OnHoldLabel from '@/components/audits/OnHoldLabel.vue';
import {getInspectionStatusTranslation} from '@/plugins/inspection/InspectionStatus';

@Component({
  components: {
    OnHoldLabel,
    Infinite,
    TransferInspections,
    Score,
    ExtendAuditAction,
    Error,
    LastAudit,
    DeleteAuditAction,
    Spinner,
    Stats,
    PlanningCard,
    Chart,
    TrustedLabel,
  },
})
export default class InProgress extends Mixins(SwimlaneMixin) {
  public key = 'paginateAudits';

  private now = new Date();

  private selected: PaginateAudits_paginateAudits_data[] = [];

  private inspectionStatusTranslations = getInspectionStatusTranslation;

  private get context(): DropdownMenuItem[] {
    return [{
      name: this.$it('planning.view-audit.title', 'View Audit') as string,
      icon: 'fa fa-search',
      action: (audit: PaginateAudits_paginateAudits_data) => {
        this.openAudit(audit);
      },
    }, {
      name: this.$it('planning.extend-audit.title', 'Extend Audit') as string,
      icon: 'far fa-calendar-plus',
      permission: 'audits-edit',
      action: (audit: PaginateAudits_paginateAudits_data) => {
        this.selected = [audit];
        this.$nextTick(() => {
          (this.$refs.extendAuditAction as ExtendAuditAction).open();
        });
      },
    }, {
      name: this.$it('planning.transfer-audit.title', 'Transfer Audit') as string,
      icon: 'fa fa-exchange-alt',
      permission: 'audits-edit',
      action: (audit: PaginateAudits_paginateAudits_data) => {
        this.selected = [audit];
        this.$nextTick(() => {
          (this.$refs.transferAuditAction as TransferInspections).onSelect();
        });
      },
    }, {
      name: this.$it('planning.delete-audit.title', 'Delete Audit') as string,
      icon: 'fa fa-times',
      permission: 'audits-delete',
      action: (audit: PaginateAudits_paginateAudits_data) => {
        this.selected = [audit];
        this.$nextTick(() => {
          (this.$refs.deleteAuditAction as DeleteAuditAction).open();
        });
      },
    }, {
      name: this.$it('planning.view-branch.title', 'View Branch') as string,
      icon: 'fa fa-warehouse',
      action: (audit: PaginateAudits_paginateAudits_data) => {
        this.$router.push({ name: 'branches-detail', params: { id: audit.dealer.id, branchId: audit.branch.id } });
      },
    }];
  }

  private calculateDateLeft(plannedAt: string, dueAt: string) {
    const planned = (new Date(plannedAt)).getTime();
    const due = (new Date(dueAt)).getTime();
    const now = (new Date()).getTime();

    const span = due - planned;
    const diff = (now - planned) / span * 100;
    const percent = Math.min(100, Math.max(diff, 0));

    return percent === 0 ? '-10px' : `${percent}%`;
  }

  private calcDaysLeft(plannedAt: string, dueAt: string) {
    const planned = (new Date(plannedAt)).getTime();
    const now = this.now.getTime();

    let days;
    if (planned < now) {
      days = Math.max(0, Math.round(
        ((new Date(dueAt).getTime()) - now) / (1000 * 60 * 60 * 24),
      ));

      return this.$it('global.days-left', { days }, '{days} day(s) left');
    }

    days = Math.max(0, Math.round(
      (planned - now) / (1000 * 60 * 60 * 24),
    ));

    return this.$it('global.in-days', { days }, 'in {days} day(s)');
  }

  private openAudit(audit: PaginateAudits_paginateAudits_data) {
    this.$router.push({
      name: 'audits-detail',
      params: {
        auditId: audit.id,
      },
    });
  }
}
