






















































































































import { Component, Mixins } from 'vue-property-decorator';
import { NextAudits_paginateBranches_data, PlanningBranch_branch } from '@/types/intrador';
// @ts-ignore
import { DropdownMenuItem } from '@/components/elements/dropdown/DropdownButton.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';
import PlanningCard from '@/components/planning/Card.vue';
import InspectionInspectorAvatar from '@/components/inspection/InspectionInspectorAvatar.vue';
import LastAudit from '@/components/planning/partials/LastAudit.vue';
import CreateAuditModal from '@/components/planning/partials/CreateAuditModal.vue';
import Error from '@/components/elements/error/Error.vue';
import PostponeAudit from '@/components/branch/actions/PostponeAudit.vue';
import SwimlaneMixin from '@/components/planning/SwimlaneMixin';
import EditBranchAction from '@/components/branch/actions/EditBranchAction.vue';
import Infinite from '@/components/planning/partials/Infinite.vue';
import DeactivateAudit from '@/components/branch/actions/DeactivateAudit.vue';
import TrustedLabel from '@/components/TrustedLabel.vue';
import {InspectionPlanningList, InspectionPlanningList_address} from '@/types/auction';
import AutomaticPlannedLabel from '@/components/audits/labels/AutomaticPlannedLabel.vue';
import NoAssetsLabel from '@/components/audits/labels/NoAssetsLabel.vue';

@Component({
  components: {
    NoAssetsLabel,
    AutomaticPlannedLabel,
    TrustedLabel,
    Infinite,
    EditBranchAction,
    DeactivateAudit,
    PostponeAudit,
    Error,
    CreateAuditModal,
    InspectionInspectorAvatar,
    LastAudit,
    PlanningCard,
    Spinner,
  },
})
export default class ToPlan extends Mixins(SwimlaneMixin) {
  public key = 'paginateInspectionPlannings';

  private selected: InspectionPlanningList_address | null = null;
  private selectedPlanning: InspectionPlanningList | null = null;

  private get context(): DropdownMenuItem[] {
    return [{
      name: this.$it('planning.plan-audit.title', 'Plan Audit') as string,
      icon: 'fa fa-calendar',
      permission: 'audits-create',
      action: (data: {planning: InspectionPlanningList, branch: InspectionPlanningList_address }) => {
        const planning = data.planning;

        if (planning.amountOfAssets && planning.amountOfAssets > 0) {
          this.planBranch(data);
        } else {
          this.importAssets(data);
        }
      },
    }, {
      name: this.$it('planning.import-assets.title', 'Import Assets') as string,
      icon: 'fa fa-download',
      permission: ['assets-import', 'inspections-bulk-create'],
      action: (data: {planning: InspectionPlanningList, branch: InspectionPlanningList_address }) => {
        this.importAssets(data);
      },
    }, {
      name: this.$it('planning.postpone-audit.title', 'Postpone Audit') as string,
      icon: 'fa fa-share',
      permission: 'audits-create', // TODO: create audit postpone permission
      action: (data: {planning: InspectionPlanningList, branch: InspectionPlanningList_address }) => {
        this.postponeAudit(data);
      },
    }, {
      name: this.$it('planning.deactivate-branch.title', 'Deactivate') as string,
      icon: 'fa fa-power-off',
      permission: 'dealers-branches-edit', // TODO: create dealer branch deactivate permission
      action: (data: {planning: InspectionPlanningList, branch: InspectionPlanningList_address }) => {
        this.selected = data.branch;
        this.$nextTick(() => {
          (this.$refs.deactivateAudit as DeactivateAudit).open();
        });
      },
    }, {
      name: this.$it('planning.change-branch.title', 'Change Branch') as string,
      icon: 'fa fa-map-marker-alt',
      permission: 'dealers-branches-edit',
      action: (data: {planning: InspectionPlanningList, branch: InspectionPlanningList_address }) => {
        this.addBranchAddress(data);
      },
    }, {
      name: this.$it('planning.view-branch.title', 'View Branch') as string,
      icon: 'fa fa-warehouse',
      action: ({ branch }: { branch: PlanningBranch_branch }) => {
        if (branch.dealer) {
          this.$router.push({ name: 'branches-detail', params: { id: branch.dealer.id, branchId: branch.id } });
        }
      },
    }];
  }

  private importAssets({ branch }: {planning: InspectionPlanningList, branch: InspectionPlanningList_address }) {
    this.$router.push({ name: 'inspections-import' });
  }

  private planBranch(data: {planning: InspectionPlanningList, branch: InspectionPlanningList_address }) {
    const branch = data.branch;
    const planning = data.planning;

    if (!branch.latitude) {
      return;
    }

    this.selected = branch;
    this.selectedPlanning = planning;
    this.$nextTick(() => {
      (this.$refs.createAuditModal as CreateAuditModal).open();
    });
  }

  private newAuditCreated() {
    this.refetch();
    this.$emit('created');
  }

  private postponeAudit(
    { planning, branch }: {planning: InspectionPlanningList, branch: InspectionPlanningList_address },
  ) {
    if (!branch.latitude) {
      return;
    }

    this.selected = branch;
    this.selectedPlanning = planning;
    this.$nextTick(() => {
      (this.$refs.postponeAudit as PostponeAudit).open();
    });
  }

  private addBranchAddress({ branch }: {planning: InspectionPlanningList, branch: InspectionPlanningList_address }) {
    this.selected = branch;
    this.$nextTick(() => {
      (this.$refs.editBranchModal as EditBranchAction).open();
    });
  }
}
