


























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  CreateInspectionsFromBranchVariables,
  NextAudits_paginateBranches_data,
  PlanningBranch_branch,
} from '@/types/intrador';
import InputSelect from '@/layouts/back-office/elements/input/InputSelect.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import InputInspector from '@/components/elements/input/InputInspector.vue';
import SnapshotSelectInspectorModal from '@/components/snapshot/import/models/SnapshotSelectInspectorModal.vue';
import {InspectionPlanningList} from '@/types/auction';

interface Inspector {
  address: NextAudits_paginateBranches_data | null;
  inspectorId: string | null;
  inspectorEmail: string | null;
  inspectorName: string | null;
}

@Component({
  components: {SnapshotSelectInspectorModal, InputInspector, InputSelect, InputText, SpinnerButton, Modal },
})
export default class CreateAuditModal extends Vue {
  @Prop(Object) private planning?: InspectionPlanningList;
  @Prop(Object) private branch!: PlanningBranch_branch;
  @Prop(Array) private refetchQueries!: any[];
  @Prop({type: Boolean, default: false}) private isManually!: boolean;

  private loading = false;
  private dates: { start: Date | null, end: Date | null } = { start: null, end: null };
  private error: string | null = null;
  private percentages = {
    '100%': 100,
    '90%': 90,
    '80%': 80,
    '70%': 70,
    '60%': 60,
    '50%': 50,
    '40%': 40,
    '30%': 30,
    '20%': 20,
    '10%': 10,
  };

  private inspector: Inspector = {
    address: null,
    inspectorId: null,
    inspectorEmail: null,
    inspectorName: null,
  };
  private plannedAt: Date | null = null;
  private dueAt: Date | null = null;
  private percentageOfInspections = 100;
  private auditFrequency: number | null = 30;
  private trusted: boolean = false;

  private get now() {
    return new Date();
  }

  private get invalid() {
    // If we have no dates selected
    if (this.plannedAt === null || this.dueAt === null) {
      return true;
    }

    // If we have no inspector selected
    if (this.inspector.inspectorEmail === null && this.inspector.inspectorId === null) {
      return true;
    }

    // Check whether we have a branch frequency, and if it is specified whether it is a number
    if (this.branch.auditFrequency === null
      && (this.auditFrequency === null || this.auditFrequency < 1 || this.auditFrequency > 365)) {
      return true;
    }

    // Planned at date cannot be equal or later than due at date
    return (this.plannedAt || 0) >= (this.dueAt || 0);
  }

  private get amountOfAssets() {
    return (this.planning) ? this.planning.amountOfAssets : this.branch.assetsAmount;
  }

  private get assetToPercentage() {
    return Math.ceil(this.amountOfAssets * (this.percentageOfInspections / 100));
  }

  public open() {
    // Get the last inspector else the default inspector else a default object
    const lastInspector = this.branch.lastInspector || { id: null, name: null, email: null };

    // Reset state
    this.error = null;
    this.inspector.address = this.branch;
    this.inspector.inspectorId = lastInspector.id;
    this.inspector.inspectorName = lastInspector.name;
    this.inspector.inspectorEmail = lastInspector.email;

    this.percentageOfInspections = this.planning?.percentageOfAssets ?? 100;
    this.auditFrequency = this.planning?.frequency ?? this.branch.auditFrequency ?? 30;
    this.trusted = this.planning?.trusted ?? false;

    const now = new Date();
    if (this.branch.nextAudit) {
      this.plannedAt = new Date(this.branch.nextAudit);
      // If the date is lower, set it to now
      if (this.plannedAt < now) {
        this.plannedAt = now;
      }
    } else {
      this.plannedAt = now;
    }

    const addDays = (this.planning?.duration ?? 2) - 1;

    // Calculate the day after
    const dayAfter = new Date(this.plannedAt.getTime());
    dayAfter.setDate(dayAfter.getDate() + addDays);
    this.dueAt = dayAfter;
    this.dates = {start: this.plannedAt, end: this.dueAt};

    (this.$refs.modal as Modal).open();
  }

  public close() {
    (this.$refs.modal as Modal).close();
  }

  @Watch('dates')
  private datesChanged() {
    if (this.dates.start) {
      this.plannedAt = this.resolveAsUTCDate(this.dates.start);
    }

    if (this.dates.end) {
      this.dueAt = this.resolveAsUTCDate(this.dates.end);
    }
  }

  private resolveAsUTCDate(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0));
  }

  private async save(spinning: (state?: boolean) => boolean) {
    if (this.loading) {
      return;
    }

    if (this.invalid) {
      return;
    }

    this.loading = spinning();

    const variables: CreateInspectionsFromBranchVariables = {
      id: this.branch.id,
      plannedAt: this.plannedAt!.toISOString(),
      dueAt: this.dueAt!.toISOString(),
      percentageOfInspections: this.percentageOfInspections,
      inspector: {
        id: this.branch.id,
      },
      trusted: this.trusted,

      // TODO: Add branch information
      // TODO: Add type of inspection?
    };

    if (this.inspector.inspectorId) {
      if (isNaN(parseInt(this.inspector.inspectorId, 10))) {
        variables.inspector.inspectorEmail = this.inspector.inspectorId;
      } else {
        variables.inspector.inspector = {
          id: this.inspector.inspectorId,
        };
      }
    } else {
      variables.inspector.inspectorEmail = this.inspector.inspectorEmail;
    }

    if (this.branch.auditFrequency === null && this.auditFrequency) {
      variables.branch = {
        auditFrequency: this.auditFrequency,
      };
    }

    try {
      const { data }: { data?: any } = await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/CreateInspectionsFromBranch.gql'),
        variables,
      });

      // Are there any errors?
      if (data === null) {
        this.error = this.$it('global.error', 'Something went wrong, please try again') as string;
      } else {
        // Clear errors and close modal
        this.error = null;
        (this.$refs.modal as Modal).close();
        this.$emit('created');
      }
    } catch (e) {
      this.error = e.message;
    }

    this.loading = spinning(false);
  }
}
