









































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { PaginateAudits_paginateAudits_data } from '@/types/intrador';

@Component
export default class Anomalies extends Vue {
  @Prop(Object) private audit!: PaginateAudits_paginateAudits_data;
}
