























import { Component, Prop, Vue } from 'vue-property-decorator';
import { PaginateAudits_paginateAudits_data } from '@/types/intrador';

@Component
export default class Stats extends Vue {
  @Prop(Object) private audit!: PaginateAudits_paginateAudits_data;
  @Prop({type: Boolean, default: true}) private needTitle!: boolean;
  @Prop() private inspectionStatusTranslations !: string[];
}
