


































import { Component, Mixins } from 'vue-property-decorator';
import { PaginateBranches_paginateBranches_data } from '@/types/intrador';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import CreateAuditModal from '@/components/planning/partials/CreateAuditModal.vue';
import SwimlaneMixin from '@/components/planning/SwimlaneMixin';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import InputAutocomplete from '@/layouts/back-office/elements/input/InputAutocomplete.vue';
import EditBranchAction from '@/components/branch/actions/EditBranchAction.vue';
import PostponeAudit from '@/components/branch/actions/PostponeAudit.vue';

@Component({
  components: {
    PostponeAudit,
    EditBranchAction,
    InputAutocomplete,
    InputText,
    CreateAuditModal,
    SpinnerButton,
    Modal,
  },
})
export default class ManuallyPlanAction extends Mixins(SwimlaneMixin) {
  protected saving = false;
  protected search: string | null = null;
  protected error: string | null = null;
  protected selected: PaginateBranches_paginateBranches_data | null = null;

  private get searchModal() {
    return this.$refs.searchModal as Modal;
  }

  private get createModal() {
    return this.$refs.createModal as CreateAuditModal;
  }

  private get editBranchModal() {
    return this.$refs.editBranchModal as EditBranchAction;
  }

  private get postponeAudit() {
    return this.$refs.postponeAudit as PostponeAudit;
  }

  public open() {
    this.search = null;
    this.searchModal.open();
  }

  public close() {
    this.searchModal.close();
  }

  private planModalSwitches(route: string) {
    switch (route) {
      case 'searchCreate':
        this.createModal.close();
        this.searchModal.open();
        break;
      case 'searchPostpone':
        this.searchModal.open();
        break;
    }
  }

  private select(branch: PaginateBranches_paginateBranches_data) {
    this.selected = branch;
    this.$nextTick(() => {
      this.close();

      if (!this.selected?.latitude) {
        this.editBranchModal.open();
      } else if (this.selected.assetsAmount <= 0) {
        this.postponeAudit.open();
      } else {
        this.createModal.open();
      }
    });
  }

  private editBranchDone({ address }: { address: PaginateBranches_paginateBranches_data }) {
    this.selected = {
      ...this.selected,
      ...address,
    };

    this.createModal.open();
  }
}
