import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {AuditStatus, NextAuditsVariables, PaginateAuditsVariables} from '@/types/intrador';
import {StateChanger} from 'vue-infinite-loading';
import {InspectionPlanningSort, PaginateInspectionPlanningsVariables, SortOrder} from '@/types/auction';

@Component
export default class InputMixin extends Vue {
  public page = 1;
  public key!: string;

  @Prop(String) protected query!: string | null;
  @Prop(Array) protected accountManagerIds!: string[] | null;

  private get nextAuditVariables(): PaginateInspectionPlanningsVariables {
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 14);

    return {
      page: 1,
      first: 20,
      nextDate: {from: new Date('1970-01-01'), to: maxDate},
      hasOpenAudits: false,
      search: this.query,
      orderBy: [{field: InspectionPlanningSort.NEXT_DATE, order: SortOrder.ASC}],
    };
  }

  private get nextAuditVariablesOld(): NextAuditsVariables {
    return {
      page: 1,
      limit: 20,
      sortBy: 'nextAudit',
      sortDirection: 'asc',
      hasNextAudit: true,
      hasCurrentAudit: false,
      accountManagerIds: this.accountManagerIds,
      search: this.query,
      searchableFields: [
        'dealer.name',
        'dealer.externalId',
        'name',
        'externalId',
      ],
    };
  }

  private get inProgressVariables(): PaginateAuditsVariables {
    return {
      page: 1,
      limit: 20,
      status: AuditStatus.IN_PROGRESS,
      accountManagerIds: this.accountManagerIds,
      search: this.query,
      searchableFields: [
        'dealer.name',
        'dealer.externalId',
        'branch.name',
        'branch.externalId',
      ],
    };
  }

  private get toReviewVariables(): PaginateAuditsVariables {
    return {
      page: 1,
      limit: 20,
      status: AuditStatus.IN_REVIEW,
      accountManagerIds: this.accountManagerIds,
      search: this.query,
      searchableFields: [
        'dealer.name',
        'dealer.externalId',
        'branch.name',
        'branch.externalId',
      ],
    };
  }

  public async refetch() {
    if (this.$refs.query) {
      this.page = 1;
      (this.$refs.query as any).getApolloQuery().refetch();
    }
  }

  @Watch('query')
  protected queryChanged() {
    this.page = 1;
  }

  @Watch('accountManagerIds')
  protected accountManagerIdsChanged() {
    this.page = 1;
  }

  private get nextAuditRefetchQueries() {
    return [{
      query: require('@/graphql/NextAudits.gql'),
      variables: this.nextAuditVariablesOld,
    }];
  }

  private get inProgressRefetchQueries() {
    return [{
      query: require('@/graphql/PaginateAudits.gql'),
      variables: this.inProgressVariables,
    }];
  }

  private get toReviewRefetchQueries() {
    return [{
      query: require('@/graphql/PaginateAudits.gql'),
      variables: this.toReviewVariables,
    }];
  }

  private get nextAndInProgressRefetchQueries() {
    return [
      // ...this.nextAuditRefetchQueries,
      ...this.inProgressRefetchQueries,
    ];
  }

  private async loadMore(): Promise<boolean> {
    if (this.page < 0) {
      return false;
    }

    this.page += 1;

    try {
      await (this.$refs.query as any).getApolloQuery().fetchMore({
        variables: {
          page: this.page,
        },

        updateQuery: (prev: any, {fetchMoreResult}: any) => {
          const next = fetchMoreResult[this.key];

          next.data = [...prev[this.key].data, ...next.data];

          if (('paginatorInfo' in next && !next.paginatorInfo.hasMorePages)
            || ('has_more_pages' in next && !next.has_more_pages)) {
            this.page = -1;
          }

          return {
            [this.key]: next,
          };
        },
      });
    } catch (e) {
      // console.error(e);
    }

    return this.page > 0;
  }

  private async infinite(stateChanger: StateChanger) {
    if (await this.loadMore()) {
      stateChanger.loaded();
    } else {
      stateChanger.complete();
    }
  }
}
