











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Chart extends Vue {
  @Prop(Boolean) private open!: boolean;
  @Prop(Array) private items!: Array<{
    label: string,
    class: string,
    percent: number,
  }>;
}
