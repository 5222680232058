var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('apollo-query',{ref:"query",attrs:{"query":require('@/graphql/PlanningPaginateAudits.gql'),"variables":_vm.inProgressVariables},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var error = ref_result.error;
var data = ref_result.data;
var query = ref.query;
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"title"},[_c('h2',[(loading || isLoading)?_c('spinner',{attrs:{"size":18,"color":"#838383"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$it('planning.swimlane.in-progress.title', 'In Progress'))+" "),(data && data.paginateAudits)?[_vm._v("("+_vm._s(data.paginateAudits.total)+")")]:_vm._e()],2)]),(error)?_c('error',{attrs:{"error":error}}):_vm._e(),(data && data.paginateAudits)?[_vm._l((data.paginateAudits.data),function(audit){return _c('planning-card',{key:("pc-audit-" + (audit.id)),attrs:{"query":require('@/graphql/Audit.gql'),"data-key":"audit","audit":audit,"context":_vm.context},on:{"click":_vm.openAudit},scopedSlots:_vm._u([{key:"labels",fn:function(){return [(audit.trusted)?_c('trusted-label'):_vm._e(),(audit.isOnHold)?_c('on-hold-label'):_vm._e()]},proxy:true},{key:"info",fn:function(){return [_c('span',{attrs:{"aria-label":((_vm.$id(audit.plannedAt, 'date')) + " - " + (_vm.$id(audit.dueAt, 'date'))),"data-balloon-pos":"down"}},[_vm._v(" "+_vm._s(_vm.calcDaysLeft(audit.plannedAt, audit.dueAt))+" ")]),(audit.score)?_c('score',{attrs:{"score":audit.score}}):_vm._e()]},proxy:true},{key:"default",fn:function(ref){
var auditData = ref.auditData;
return [_c('div',{staticClass:"timeline"},[_c('h3',[_vm._v(_vm._s(_vm.$it('planning.card.timeline.title', 'Timeline')))]),_c('div',{staticClass:"time"},[_c('div',{staticClass:"dot start"}),_c('div',{staticClass:"dot now",style:({left: _vm.calculateDateLeft(auditData.plannedAt, auditData.dueAt)}),attrs:{"data-label":_vm.$it('global.now', 'Now')}}),_c('div',{staticClass:"dot end"})]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto"},[_vm._v(" "+_vm._s(_vm.$id(auditData.plannedAt, 'dateWithoutYear'))+" ")]),_c('div',{staticClass:"col"}),_c('div',{staticClass:"col-auto"},[_vm._v(" "+_vm._s(_vm.$id(auditData.dueAt, 'dateWithoutYear'))+" ")])])]),_c('stats',{attrs:{"audit":auditData,"inspectionStatusTranslations":_vm.inspectionStatusTranslations}}),_c('last-audit',{attrs:{"audits":auditData.branch.endedAudits}}),_c('div',{staticClass:"actions"},[_c('button',{staticClass:"btn btn-outline-primary",on:{"click":function($event){$event.stopPropagation();return _vm.openAudit(auditData)}}},[_vm._v(" "+_vm._s(_vm.$it('planning.view-audit.title', 'View Audit'))+" ")])])]}},{key:"footer",fn:function(ref){
var isOpen = ref.isOpen;
var auditData = ref.auditData;
return [_c('chart',{attrs:{"items":[{
          class: 'submitted',
          label: _vm.$it('inspection.status.submitted.title', 'Submitted'),
          percent: auditData.submittedInspections / (auditData.openInspections + auditData.submittedInspections) * 100,
        }, {
          class: 'open',
          label: _vm.$it('global.open', 'Open'),
          percent: auditData.openInspections / (auditData.openInspections + auditData.submittedInspections) * 100,
        }],"open":isOpen}})]}}],null,true)})}),_c('infinite',{attrs:{"identifier":"in-progress","infinite":_vm.infinite}})]:_vm._e(),(_vm.selected.length > 0)?[_c('delete-audit-action',{ref:"deleteAuditAction",attrs:{"selected":_vm.selected,"audit-id":_vm.selected[0].id,"refetch-queries":_vm.inProgressRefetchQueries,"single":""}}),_c('extend-audit-action',{ref:"extendAuditAction",attrs:{"selected":_vm.selected,"audit-id":_vm.selected[0].id,"refetch-queries":_vm.inProgressRefetchQueries,"single":""}}),_c('transfer-inspections',{ref:"transferAuditAction",attrs:{"selected":_vm.selected,"audit-id":_vm.selected[0].id,"is-audit":true,"single":""}})]:_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }