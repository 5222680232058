





































import { Component, Mixins } from 'vue-property-decorator';
import InfiniteLoading from 'vue-infinite-loading';
import ToPlan from '@/components/planning/ToPlan.vue';
import InProgress from '@/components/planning/InProgress.vue';
import ToReview from '@/components/planning/ToReview.vue';
import ActionBar from '@/layouts/back-office/elements/ActionBar.vue';
import DataTableSearchBar from '@/layouts/back-office/elements/datatable/components/DataTableSearchBar.vue';
import ManuallyPlanAction from '@/components/planning/partials/ManuallyPlanAction.vue';
import FilterButton from '@/layouts/back-office/elements/filters/FilterButton.vue';
import SwimlaneMixin from '@/components/planning/SwimlaneMixin';
import AccountManagerFilterMixin from '@/filters/dataTable/AccountManagerFilterMixin';
import {Meta} from '@sophosoft/vue-meta-decorator';

@Component({
  components: {
    FilterButton,
    ManuallyPlanAction,
    DataTableSearchBar,
    ActionBar,
    ToReview,
    InProgress,
    ToPlan,
    InfiniteLoading,
  },
})
export default class Planning extends Mixins(AccountManagerFilterMixin) {
  // TODO: Prepopulate with own ID
  private accountManager: string[]|null = [];

  private get accountManagerIds() {
    if (this.accountManager && this.accountManager.length > 0) {
      return this.accountManager;
    }

    return null;
  }

  private async updateView() {
    this.$nextTick(() => {
      (this.$refs.laneToPlan as SwimlaneMixin).refetch();
      (this.$refs.laneInProgress as SwimlaneMixin).refetch();
      (this.$refs.laneToReview as SwimlaneMixin).refetch();
    });
  }

  // TODO: We need to refresh when navigating back. Maybe we can add a query parameter which specifies this?
  private mounted() {
    this.updateView();

    if (this.accountManagerFilter.default && this.accountManagerFilter.default.length > 0) {
      this.accountManager = this.accountManager?.concat(this.accountManagerFilter.default) || [];
    }
  }

  @Meta
  private getMetaInfo() {
    return {
      title: this.$it('menu.planning.title', 'Planning') as string,
    };
  }
}
