











































import {Component, Prop, Vue, Mixins, Watch, Emit, Model} from 'vue-property-decorator';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';

@Component({
  components: {SpinnerButton, Modal, InputMixin, InputText},
})
export default class SnapshotSelectInspectorModal extends Mixins(InputMixin) {
  @Model('change') public model!: any;
  @Prop({type: Number, default: 5}) protected numberOfSuggestions!: string[];
  @Prop({type: Object, default: () => ({})}) protected variables!: any;
  @Prop({type: Function}) protected closeModal!: (() => void);

  protected value: any;
  private newInspectorEmail: string = '';
  private canSave: boolean = false;

  public open() {
    (this.$refs.createInspector as Modal).open();

    setTimeout(() => {
      (this.$refs.input as InputText).becomeFirstResponder();
    }, 150);
  }

  @Watch('value')
  @Emit('input')
  protected onValueChange(value: any | null) {
    return this.value;
  }

  @Watch('newInspectorEmail')
  private emailInputChanged() {
    if (this.isEmail()) {
      this.canSave = true;
    }
  }

  private isEmail() {
    return this.$validateEmail(this.newInspectorEmail);
  }

  private createInspector() {
    // If we cannot save, stop this method
    if (!this.canSave) {
      return;
    }

    // When a new inspector gets added, we dont have an ID. If we set the new email to inspectorId,
    // we know that we are talking about a new email that is not in the system.
    this.value.inspectorId = this.newInspectorEmail;
    this.value.inspectorEmail = null;
    this.value.inspectorName = null;

    const modal = this.$refs.createInspector as Modal;
    modal.close();

    this.closeModal();

  }
}
