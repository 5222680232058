
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"InspectionPlanningList"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"InspectionPlanning"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"address"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AddressList"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"previousDate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"nextDate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"frequency"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"trusted"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"duration"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"automaticPlannedAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"amountOfAssets"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"percentageOfAssets"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":275}};
    doc.loc.source = {"body":"#import \"@/graphql/fragments/addresses/AddressList.gql\"\n\nfragment InspectionPlanningList on InspectionPlanning {\n  id\n\n  address {\n    ...AddressList\n  }\n\n  previousDate\n  nextDate\n  frequency\n  trusted\n  duration\n  automaticPlannedAt\n  amountOfAssets\n  percentageOfAssets\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/addresses/AddressList.gql").definitions));


      module.exports = doc;
    
