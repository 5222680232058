



















































import {Component, Prop, Vue, Watch, Emit, Model} from 'vue-property-decorator';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import SnapshotCreateInspectorModal from '@/components/snapshot/import/models/SnapshotCreateInspectorModal.vue';
import InputGroup from '@/layouts/back-office/elements/input/InputGroup.vue';

@Component({
  components: {InputGroup, SpinnerButton, Modal, SnapshotCreateInspectorModal},
})
export default class SnapshotSelectInspectorModal extends Vue {
  @Model('change') public model!: any;

  protected value: any;

  @Prop({type: String, default: 'right'}) private balloonPositioning!: string;
  private selectedItem: any = null;

  public open() {
    const modal = this.$refs.selectInspector as Modal;
    modal.open();
  }

  @Watch('model', {immediate: true})
  private onModelChange(model: any) {
    this.value = model;
  }

  @Watch('value')
  @Emit('input')
  private onValueChange(value: any | null) {
    return this.value;
  }

  private closeModal() {
    const modal = this.$refs.selectInspector as Modal;
    modal.close();
  }

  private save() {
    this.value.inspectorId = this.selectedItem.uid;
    this.value.inspectorEmail = this.selectedItem.email;
    this.value.inspectorName = this.selectedItem.name;

    this.closeModal();
  }

  /* get inspector name or id if inspectorname is set to null (in case a new inspector is created) */
  private getInspectorName() {
    return this.value.inspectorName !== null ? this.value.inspectorName : this.value.inspectorId;
  }

}
