
















































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import InputDate from '@/layouts/back-office/elements/input/InputDate.vue';
import InputSelect from '@/layouts/back-office/elements/input/InputSelect.vue';
import InputTextarea from '@/layouts/back-office/elements/input/InputTextarea.vue';
import {
  AuditRating,
  InspectionPlanningList,
  PostponeInspectionPlanningVariables,
  PostponeReason,
} from '@/types/auction';

@Component({
    components: { ActionModal, InputDate, InputSelect, InputTextarea },
  })
  export default class PostponeAudit extends Mixins<ActionMixin<InspectionPlanningList>>(ActionMixin) {
    @Prop(Array) private refetchQueries!: any[];
    @Prop({type: Boolean, default: false}) private warning!: boolean;

    private nextAudit: Date = new Date();
    private remark: string | null = null;
    private reason: PostponeReason | null = null;
    private rating: AuditRating | null = null;

    public get variables(): PostponeInspectionPlanningVariables|null {
      if (!this.firstAvailable || !this.nextAudit || !this.reason) {
        return null;
      }

      return {
        id: this.firstAvailable.id,
        postponedTo: this.nextAudit,
        reason: this.reason,
        rating: this.rating,
      };
    }

    public open() {
      // Reset data
      this.remark = null;
      this.rating = null;
      this.reason = null;
      this.nextAudit = new Date();

      // Calculate new audit date
      if (this.firstAvailable && this.firstAvailable.frequency) {
        this.nextAudit.setDate((new Date()).getDate() + this.firstAvailable.frequency);
      } else {
        this.nextAudit.setDate((new Date()).getDate() + 60);
      }

      // Open model
      (this.$refs.actionModal as ActionModal).open();
    }

    public close() {
      (this.$refs.actionModal as ActionModal).close();
    }

    private get now(): Date {
      return new Date();
    }

    private get ratingOptions(): { [key: string]: AuditRating } {
      return {
        [this.$it('audit.rating.content.title', 'Satisfactory') as string]: AuditRating.CONTENT,
        [this.$it('audit.rating.half-content.title', 'Satisfactory with reservations') as string]:
          AuditRating.HALF_CONTENT,
        [this.$it('audit.rating.not-content.title', 'Unsatisfactory') as string]: AuditRating.NOT_CONTENT,
      };
    }

    private get reasonOptions(): { [key: string]: PostponeReason } {
      return {
        [this.$it('audit.reason.frequency-changed', 'Audit frequency changed') as string]:
          PostponeReason.AUDIT_FREQUENCY_CHANGED,
        [this.$it('audit.reason.by-external-party', 'Audit by external party') as string]:
          PostponeReason.AUDIT_BY_EXTERNAL_PARTY,
        [this.$it('audit.reason.no-assets-in-stock', 'No assets in stock') as string]:
          PostponeReason.NO_ASSETS_IN_STOCK,
      };
    }

    private get ratingEnabled() {
      return this.reason === PostponeReason.AUDIT_BY_EXTERNAL_PARTY;
    }

    private get reasonPostponed() {
      return this.reason === PostponeReason.AUDIT_FREQUENCY_CHANGED;
    }

    private get canSave() {
      if (this.variables === null) {
        return false;
      }

      return this.reason !== PostponeReason.AUDIT_BY_EXTERNAL_PARTY || this.rating !== null;
    }

    protected onSelect() {
      // this.open()
    }

    @Watch('reason')
    private reasonChanged() {
      this.rating = null;
    }
  }
