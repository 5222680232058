




















import {Component, Mixins, Prop} from 'vue-property-decorator';
import {AuditRating, Branch_branch, ExtendAuditVariables, PostponeAuditVariables} from '@/types/intrador';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import InputDate from '@/layouts/back-office/elements/input/InputDate.vue';
import InputSelect from '@/layouts/back-office/elements/input/InputSelect.vue';
import InputTextarea from '@/layouts/back-office/elements/input/InputTextarea.vue';

@Component({
  components: { ActionModal, InputDate, InputSelect, InputTextarea },
})
export default class ExtendAuditAction extends Mixins<ActionMixin<any>>(ActionMixin) {
  @Prop(Array) private refetchQueries!: any[];

  private dueAt: Date | null = new Date();

  private get now(): Date {
    return new Date();
  }

  public get variables(): ExtendAuditVariables | null {
    if (this.dueAt) {
      return {
        id: this.firstItem!.id,
        dueAt: this.$toDate12Hour(this.dueAt).toISOString(),
      };
    }

    return null;
  }

  public get firstItem(): Branch_branch {
    return this.selected[0];
  }

  public open() {
    // Reset data
    this.dueAt = new Date();

    // Open model
    (this.$refs.actionModal as ActionModal).open();
  }

  protected onSelect() {
    // this.open()
  }
}
